import { Link } from "gatsby";
import React from "react";
import Layout from "../../Layout";
import "./style.scss";
import SEO from "../../seo";
export default function ApplicationProcess() {
  return (
    <Layout>
      <SEO
        title="Online Registration Process | Chinmaya Vidyalaya Vasant Vihar Admission Procedure"
        description="Know more about Online registration process, fee structure, admission Form, and documents required in Chinmaya Vidyalaya Vasant Vihar admission."
        img="https://chinmayavvdelhi.ac.in/images/Blackele/Chinmaya_link_preview.jpg"
        keywords="Chinmaya vidyalaya vasant vihar, chinmaya trust, chinmaya mission, online vidyalaya,online admission,schools in delhi,online registrations procedure, admission procedure"
      />
      <div className="applicationprocess">
        <div className="applicationprocess__section1">
          <h1 className="heading--white">Process For Online Registration</h1>

          <h2>Step 1</h2>
          <p>
            To register, please fill the complete details on the online form,
            and pay the online registration fee of Rs. 25 (non-refundable).
            Submission of the online Registration form is a request for
            admission and does not guarantee admission.
            <br />
            <br /> Please note{" "}
            <strong>
              Incomplete forms will not be considered for admission.
            </strong>
          </p>

          <h2>Step 2</h2>
          <p>
            <strong>
              Kindly note: the printed form, along with photocopies of
              documents, is to be submitted at the time of verification, only
              after selection.
            </strong>
          </p>

          <h2> Next step:</h2>
          <p>
            The list of shortlisted candidates will be displayed on the school
            website and notice boards, on dates as listed in the Schedules &
            Timelines section. The school does not conduct interviews or
            interactions with the children.
          </p>

          <div className="applicationprocess__section1__buttons">
            <a href="/registrations/" className="btn-white">
              Online Admission Form
            </a>

            <a href="/fee-structure/" className="btn-white">
              View Fee Structure
            </a>

            <Link to="/bus-routes/" className="btn-white">
              Current Bus Routes
            </Link>
          </div>
        </div>
        <div className="tel">
          FOR ADMISSION ENQUIRY CALL -{" "}
          <a href="tel:9999834424">
            <strong>9999834424</strong>
          </a>
        </div>

        <div className="applicationprocess__section2">
          <h2 className="heading">Document Checklist</h2>
          <br />
          <p>
            The list of documents to be enclosed with the filled-up registration
            form is as follows. School authorities retain the right to cancel
            admissions if information submitted is found to be false.
          </p>

          <div className="applicationprocess__section2__buttons">
            <a
              href="https://chinmayavvdelhi.ac.in/assets/pdfs/admissions-25-26/DOCUMENT-REQUIRED-NURSERY-2025-26.pdf"
              target="_blank"
              className="btn-dark"
              download
            >
              Checklist For Nursery <i className="fas fa-file-download"></i>
            </a>

            <a
              href="https://chinmayavvdelhi.ac.in/assets/pdfs/admissions-25-26/DOCUMENT-REQUIRED-KG-&-1st-2025-26.pdf"
              target="_blank"
              className="btn-dark"
              download
            >
              Checklist For KG - I <i className="fas fa-file-download"></i>
            </a>

            <a
              href="https://chinmayavvdelhi.ac.in/assets/pdfs/admissions-25-26/DOCUMENT-REQUIRED-II-to-IX-2025-26.pdf"
              target="_blank"
              className="btn-dark"
              download
            >
              Checklist For II - IX <i className="fas fa-file-download"></i>
            </a>
          </div>
        </div>
      </div>
    </Layout>
  );
}
